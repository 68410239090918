"use client"

import { StyledLink } from "@/components/styled-link"
import { VideoModalContext } from "@/contexts/video-modal"
import { myProfile } from "@/data/my-profile"
import { env } from "@/env.mjs"
import { cloudinaryLoader } from "@/libs/cloudinary-image"
import type { CaseStudyCard as Card } from "@/models/case-study-card"
import { toMuxVideoForDisplay } from "@/models/mux-video"
import { startOfDay } from "date-fns"
import { ArticleJsonLd, VideoJsonLd } from "next-seo"
import Image from "next/image"
import Link from "next/link"
import { useContext, type ReactNode } from "react"

const now = startOfDay(new Date())

export function CaseStudyCard(card: Readonly<Card>): ReactNode {
  const context = useContext(VideoModalContext)
  if (!context) {
    throw new Error(
      "VideoModalContext is not defined. Did you forget to wrap your component with the VideoModalProvider?"
    )
  }

  const { setOpen, setData } = context
  const { id, orientation, caseStudy, navigationAction } = card
  const combinedClientTitle = `${caseStudy.client.name} · ${caseStudy.title}`
  const videoDetails = toMuxVideoForDisplay(caseStudy.primaryVideo)

  return (
    <>
      <article
        id={id}
        className={`xl:flex xl:items-center space-y-5 group/card xl:space-y-0 xl:space-x-10 ${
          orientation === "IMAGE_LAST" ? "xl:flex-row-reverse xl:space-x-reverse" : ""
        }`}
      >
        <div className="relative overflow-hidden will-change-transform xl:w-1/2 rounded-xl">
          <div className="pb-[56.25%] xl:pb-0 xl:h-[550px] 2xl:h-[550px] relative">
            <Image
              id={`image:${id}`}
              src={caseStudy.primaryImage}
              alt={caseStudy.title}
              fill={true}
              sizes="(max-width: 768px) 200vw, (max-width: 1200px) 100vw"
              quality={80}
              loading="lazy"
              style={{ objectFit: "cover" }}
              className="transition-all duration-[0.5s] pointer-events-none select-none"
              loader={cloudinaryLoader}
            />
          </div>
        </div>
        <div className="space-y-5 xl:px-0 xl:w-1/2">
          <div className="space-y-1">
            <h4 className="m-0">{caseStudy.title}</h4>
            <p className="text-sm opacity-70">{caseStudy.client.name}</p>
          </div>
          <div className="space-y-4">
            <p dangerouslySetInnerHTML={{ __html: caseStudy.description }}></p>
            {caseStudy.technologyStack.length ? (
              <div className="xl:pb-3 xl:hidden">
                <p className="sr-only">Project technologies used:</p>
                <ul className="flex flex-wrap pl-4 leading-loose" title="Project technologies used">
                  {caseStudy.technologyStack.map((tech, idx) => (
                    <li className="pr-6 text-sm" key={idx}>
                      {tech.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {navigationAction?.type === "video-modal" && videoDetails ? (
              <>
                <StyledLink
                  as="button"
                  onClick={() => {
                    setData(caseStudy)
                    setOpen(true)
                  }}
                  title="Watch the case study"
                  className="text-sm"
                >
                  Watch the case study
                </StyledLink>
                <VideoJsonLd
                  useAppDir={true}
                  id={videoDetails.id}
                  keyOverride={videoDetails.id}
                  name={caseStudy.title}
                  description={caseStudy.description}
                  thumbnailUrls={[videoDetails.posterUrl]}
                  uploadDate={videoDetails.uploadDate}
                  contentUrl={videoDetails.srcUrl}
                />
              </>
            ) : navigationAction?.type === "learn-more-href" ? (
              <StyledLink as="button" className="text-sm">
                <Link
                  href={navigationAction.href}
                  title="Learn more about this project"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  prefetch={false}
                >
                  Learn more about the project&nbsp;&rarr;
                </Link>
              </StyledLink>
            ) : navigationAction?.type === "website-href" ? (
              <StyledLink as="button" className="text-sm">
                <Link
                  href={navigationAction.href}
                  title="Visit the live website"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  prefetch={false}
                >
                  Visit the live site&nbsp;&rarr;
                </Link>
              </StyledLink>
            ) : navigationAction?.type === "path" ? (
              <StyledLink as="button" className="text-sm">
                <Link href={navigationAction.path} title="Learn more about this project">
                  Learn more about the project&nbsp;&rarr;
                </Link>
              </StyledLink>
            ) : null}
          </div>
        </div>
      </article>
      <ArticleJsonLd
        useAppDir={true}
        id={`article:${id}`}
        keyOverride={`article:${id}`}
        title={combinedClientTitle}
        description={caseStudy.description}
        images={
          caseStudy.primaryImage
            ? [cloudinaryLoader({ src: caseStudy.primaryImage, width: 1200 })]
            : []
        }
        url={new URL(`/#${id}`, env.NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN).toString()}
        authorName={{
          name: myProfile.name,
          url: new URL(env.NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN).toString(),
        }}
        datePublished={now.toISOString()}
      />
    </>
  )
}
